'use client';

import dynamic from 'next/dynamic';
import { useEffect, useState, useRef } from 'react';

export default function ProductFruitsProvider() {
  const [userInfo, setUserInfo] = useState(null);
  const initializedRef = useRef(false); // Track initialization status

  const ProductFruits = dynamic(
    () => import('react-product-fruits').then(mod => mod.ProductFruits),
    { ssr: false }
  );

  useEffect(() => {
    if (!initializedRef.current) {
      // Only set user info and initialize once
      const user = JSON.parse(localStorage.getItem('userData') as string);

      if (user) {
        setUserInfo({
          username: user.email,
          email: user.email,
          firstname: user.firstName,
          lastname: user.lastName,
          signUpAt: user.signUpAt,
          role: 'external-lab',
          props: {},
        });
      }

      initializedRef.current = true; // Set to true to prevent re-initialization
    }
  }, []);

  return (
    <>
      {userInfo && (
        <ProductFruits
          workspaceCode="zRT12ylUXJnxl5sG"
          language="it"
          user={userInfo}
          debug={false}
          lifeCycle="neverUnmount"
          config={{ disableLocationChangeDetection: true }}
        />
      )}
    </>
  );
}
